import video from "../assets/Video Editing.jpeg";
import social from "../assets/social-media-marketing-in-Dubai.jpg"
export const programsDataTop = [
  {
    image: (
      <img
        src={social}
        alt="Design Image"
      />
    ),
    heading: "Social Media Marketing",
    details:
      "Need dynamic social media marketing? Social Surge excels on Instagram and Facebook, crafting engaging content, ads, and driving impactful online growth. Ready to boost your presence?"
  },
  {
    image: <img src={video} alt="Design Image" />,
    heading: "Video Editing",
    details:
      "Want to enhance your video content? Social Surge specializes in top-notch video editing, bringing your vision to life for outstanding results. Elevate your content with us."
  }
];
