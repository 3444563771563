import React, { useState, useEffect } from "react";
import "./Hero.css";
import Header from "./Header/Header";
import Growth from "../../assets/Growth.png";
import hero_image from "../../assets/Heroo.png";
import back from "../../assets/Circle.png";
import Contact from "../../Contact/Contact";
import { motion } from "framer-motion";
const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const [mobile, setMobile] = useState(window.innerWidth <= 768 ? true : false);

  // Add event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768 ? true : false);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="hero" id="Home">
      <div className="left-h" id="Home">
        <Header />
        {/* The Best Ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "135px" : "245px" }}
            animate={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>we make your brand widely visible</span>
        </div>

        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Your Vision,</span>
          </div>
          <div>
            <span>Our Stratergy</span>
          </div>
          <div>
            <span>
              Transforming Your Dreams into Tangible Success, One Strategic Move
              at a Time.
            </span>
          </div>
        </div>
      </div>
      <div className="right-h">
        {!mobile && (
          <button className="btn">
            <li>Contact Us Now</li>
          </button>
        )}

        <motion.div
          initial={{ right: "5rem" }}
          whileInView={{ right: "10rem" }}
          transition={transition}
          className="growth"
        >
          <img src={Growth} alt="" />
          <div>
            <span>Growth In </span>
            <span>Business</span>
          </div>
        </motion.div>

        {/* Hero Images */}

        <img src={back} className="hero-img-back" />
        <img src={hero_image} className="hero-img" />
      </div>
    </div>
  );
};

export default Hero;
