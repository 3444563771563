import React, { useState, useEffect } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import "./Slider.css";
import slide from "./slide";

const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const autoSlideInterval = 3200;

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // Function to handle automatic sliding
  const autoSlide = () => {
    nextSlide();
  };

  useEffect(() => {
    // Set up the interval for auto-sliding
    const intervalId = setInterval(autoSlide, autoSlideInterval);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [current]);

  if (!Array.isArray(slides) || slides.length === 0) {
    return null;
  }

  return (
    <section className="slider" id="ExtraService">
      <div className="slider-wrapper">
        <h2 className="slide-header">
          other services<span className="stroke-text"> We Provide</span>
        </h2>
        <div className="arrow-container">
          <FaArrowAltCircleLeft
            className="custom-left-arrow"
            onClick={prevSlide}
          />
          <FaArrowAltCircleRight
            className="custom-right-arrow"
            onClick={nextSlide}
          />
        </div>
        <div className="image-container">
          {slides.map((slide, index) => (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && (
                <div className="image-wrapper">
                  <img src={slide.url} alt="travel image" className="image" />
                  <div
                    className={index === current ? "overlay active" : "overlay"}
                  >
                    <h3>{slide.name}</h3>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImageSlider;
