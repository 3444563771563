import freelance from "../../assets/FreeLancer.jpg"
import poster from "../../assets/Poster.webp";

export const slide = [
  {
    name: "Project/Freelance Based",
    url: freelance,
  },
  {
    name: "Logo Designing",
    url: "https://th.bing.com/th/id/OIG.BssuohLpumhhuSr_kPGz?pid=ImgGn"
  },
  {
    name: "Poster Designing",
    url: "https://th.bing.com/th/id/OIG.1B.9UcKDCyN6_cVmrt9v?pid=ImgGn",
  },
];
