import "./App.css";
import ContactForm from "./components/ContactMe/ContactMe";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Program from "./components/Hero/Program/Program";
import ImageSlider from "./components/Slider/Slider";
import { slide } from "./components/Slider/slide";
//
function App() {
  return (
    <div className="App">
      <Hero />
      <Program />
      <ImageSlider slides={slide} id="ExtraService" />
      {/* <Testimonial /> */}

      <ContactForm
        className="form"
        style={{ backgroundColor: "var(--green)", marginBottom: "0" }}
      />
      <Footer className="footer" style={{ marginTop: "0" }} />
    </div>
  );
}

export default App;
