import content from "../assets/Content.webp";
import design from "../assets/Website Design.jpg";
export const programsDataBottom = [
  {
    image: <img src={design} alt="Website Designing" />,
    heading: "Website Designing",
    details:
      "Looking for outstanding web design? Social Surge excels in crafting stunning websites, ensuring your brand shines with captivating visual allure. Ready to bring your web vision to life?"
  },
  {
    image: <img src={content} alt="Content Creation" />,
    heading: "Content Creation",
    details:
      "Seek captivating content? Social Surge crafts compelling, conversation-starting narratives, fostering lasting brand impact. Elevate your brand with our creativity."
  }
];
