import React, { useState, useEffect } from "react";
import {
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineWhatsApp
} from "react-icons/ai";
import "./Footer.css";
import logo1 from "../../assets/logo1.png";
const Footer = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 768 ? true : false);

  // Add event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768 ? true : false);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="footer" id="Footer">
      <div className="left-fo">
        <img src={logo1} />
        {mobile ? (
          <p>&copy; Social Surge, {new Date().getFullYear()}</p>
        ) : (
          <p>
            &copy; Social Surge, {new Date().getFullYear()}. All rights reserved
          </p>
        )}
      </div>

      <div className="right-fo">
        <a
          href="https://instagram.com/social___surge?igshid=MzRlODBiNWFlZA=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineInstagram className="icon" />
        </a>
        <a href="mailto:socialsurgenetwork@gmail.com">
          <AiOutlineMail className="icon" />
        </a>
        <a
          href="https://wa.me/+918007981064"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineWhatsApp className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
